<template>
  <div class="flex justify-center px-6 lg:px-0">
    <div class="w-full lg:w-4/12 pt-12">
      <div class="text-center mb-10">
        <h2 class="font-bold text-3xl">New password</h2>
      </div>
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert" v-if="validationErrors || error">
        <strong class="font-bold">Please fix the following errors:</strong>
        <ul v-for="(category, index) in validationErrors" :key="index">
          <li v-for="(error, i) in category" :key="i">
            {{error}}
          </li>
        </ul>
        <span v-if="error">{{error}}</span>
      </div>
      <ValidationObserver ref="resetPasswordForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(resetPassword)" method="post" autocomplete="off">
          <div class="grid grid-cols-1 row-gap-4">
            <div>
              <ValidationProvider rules="required|email" vid="email" name="Email" v-slot="{ errors }">
                <label class="form-lable">Email</label>
                <input
                  v-model="form.email"
                  class="form-input w-full"
                  :class="{'border-red-400': errors.length}"
                  type="email"
                  placeholder="Email"
                >
                <p class="text-red-700 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div>
              <ValidationProvider rules="required|min:8" name="Password" vid="password" v-slot="{ errors }">
                <label class="form-lable">New Password</label>
                <input
                  v-model="form.password"
                  class="form-input w-full"
                  :class="{'border-red-400': errors.length}"
                  type="password"
                >
                <p class="text-red-700 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div>
              <ValidationProvider rules="required|confirmed:password" vid="confirmation" name="Password confirmation" v-slot="{ errors }">
                <label class="form-lable">Confirm Password</label>
                <input
                  v-model="form.password_confirmation"
                  class="form-input w-full"
                  :class="{'border-red-400': errors.length}"
                  type="password"
                >
                <p class="text-red-700 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <button class="btn bg-gray-600 my-10 flex justify-center" type="submit">
            <span v-if="loading">
              <loading-icon class="h-5 w-5 mr-4"/>
            </span>
            <span v-else>Update</span>
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
    data() {
      return {
        loading: false,
        form: {
          token: null,
          email: null,
          password: null,
          password_confirmation: null,
        },
        validationErrors: null,
        error: null
      }
    },
    methods: {
        resetPassword() {
          this.loading = true;
          this.form.token = this.$route.params.token;
          this.$store.dispatch('auth/resetPassword', this.form)
            .then(response => {
              if (response) {
                this.$router.push({name: 'Login'})
              }
            })
            .catch(errors => {
              if (errors.response.data.errors) {
                this.validationErrors = errors.response.data.errors;
              }
              if (errors.response.data.message) {
                this.error = errors.response.data.message
              }
              this.form.password = null;
              this.form.password_confirmation = null;
              this.form.email = null;
            })
            .finally(() => this.loading = false)
        },
    },

    mounted() {
      if (this.$route.query.email) {
        this.form.email = this.$route.query.email
      }
    }
}
</script>
